import AccountDeletedIcon from '../icons/AccountDeletedIcon';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';

export default function AccountDeletedDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-softLinen w-[360px] border border-cardBorder75 p-8">
        <DialogHeader>
          <DialogTitle className="flex flex-col items-center justify-center space-y-8 font-display text-2xl font-extrabold text-darkGreen">
            <AccountDeletedIcon />
            <span>Account deleted</span>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-center text-sm font-normal text-primaryTextColor">
          Your Custody Navigator account has been successfully deleted.
        </DialogDescription>
        <DialogFooter className="flex flex-col items-center justify-center space-y-2 sm:flex-col sm:justify-center">
          <Button
            type="button"
            onClick={onClose}
            className="flex items-center justify-center rounded-full border bg-darkGreen px-8 py-4 text-sm font-medium text-beige100"
          >
            <span>Dismiss</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
