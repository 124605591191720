import useMediaQueryMatches from '~/hooks/useMediaQueryMatches';
import OFWLogoIcon from '../icons/OFWLogoIcon';

export default function Footer() {
  const isNarrowScreen = useMediaQueryMatches('(max-width:454px)');
  const footerStyles = isNarrowScreen ? 'flex-col justify-center' : 'justify-between';

  return (
    <div id="footer" className={`flex h-16 items-center px-8 ${footerStyles}`}>
      <p className="flex flex-wrap items-center justify-center text-sm font-light text-secondaryBrown">
        {`© ${new Date().getFullYear()} OurFamilyWizard, LLC`}
      </p>
      <div className="flex flex-col items-center justify-center text-sm font-light text-secondaryBrown">
        <p className="flex items-center">
          <span className="flex pr-1">Made with</span> <OFWLogoIcon />
        </p>
        <ul className="flex items-center justify-center">
          <li>
            <a
              className="text-xs font-light text-darkGreen underline"
              href="https://legal.custodynavigator.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <span className="flex items-center justify-center px-1 text-xs">{'|'}</span>
          <li>
            <a
              href="https://legal.custodynavigator.com/terms"
              target="_blank"
              rel="noreferrer"
              className="text-xs font-light text-darkGreen underline"
            >
              Terms
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
