import { json, type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import Header from '../components/landingPage/Header';
import Footer from '../components/route/Footer';
import useStoreParams from '../hooks/useStoreParams';
import LandingPageImage from '~/components/landingPage/Image';
import LandingPageContent from '../components/landingPage/LandingPageContent';
import { commitSession, getSession } from '~/sessions';
import { useLoaderData } from '@remix-run/react';
import React from 'react';
import AccountDeletedDialog from '~/components/dialogs/AccountDeletedDialog';
import { ACCOUNT_DELETED, NOTIFICATION } from '~/constants/cookies';

export const meta: MetaFunction = () => {
  return [
    { title: 'Custody Navigator: Create Parenting Plans & Schedules | Co-Parenting Tools & Resources' },
    {
      name: 'description',
      content:
        'Custody Navigator helps co-parents create comprehensive parenting time schedules with less effort. Access user-friendly tools and templates to create your custody arrangements smoothly.',
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request);
  const notification = session.get(NOTIFICATION) || null;

  return json(
    {
      notification,
    },
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    }
  );
}

export default function Index() {
  const { notification } = useLoaderData<typeof loader>();
  const [isShowModal, setIsShowModal] = React.useState(notification === ACCOUNT_DELETED);
  useStoreParams();

  return (
    <div className="flex min-h-screen flex-col justify-between bg-primaryBackground">
      <div className="grow">
        <div className="mx-auto my-0 flex max-w-screen-sm flex-col justify-start px-4 pb-4 screen-md:max-w-[1075px] screen-md:pb-8 screen-lg:max-w-[1281px]">
          <Header />

          <div className="mx-auto flex w-full flex-col items-center justify-center gap-2 screen-md:flex-row-reverse">
            <LandingPageImage />
            <LandingPageContent />
          </div>
        </div>
      </div>

      <div className="pb-2">
        <Footer />
      </div>

      <AccountDeletedDialog isOpen={isShowModal} onClose={() => setIsShowModal(false)} />
    </div>
  );
}
