import { Button } from '../ui/button';
import Title from '../images/Title';
import CirclePlusIcon from '../icons/CirclePlusIcon';
import { PageRoutes } from '~/constants/routes';
import { Link } from '@remix-run/react';
import CustodyNavigatorIcon from '../icons/CustodyNavigatorIcon';

export default function Header() {
  return (
    <div className="flex h-20 items-center justify-between">
      <div className="flex items-center justify-start">
        <CustodyNavigatorIcon />
        <span className="pl-4">
          <Title />
        </span>
      </div>
      <a
        className="hidden items-center justify-between rounded-3xl border border-dashed border-cardBorder px-2 py-1 normal-case hover:bg-inherit screen-md:flex"
        href={PageRoutes.TEMPLATES}
      >
        <span className="pr-1 text-base font-semibold text-primaryTextColor">Start here</span>
        <CirclePlusIcon />
      </a>
      <Button
        aria-label="Create your schedule"
        size="icon"
        className="bg-greenButtonBackground screen-md:hidden"
        href={PageRoutes.TEMPLATES}
        asChild
      >
        <Link to={PageRoutes.TEMPLATES}>
          <CirclePlusIcon />
        </Link>
      </Button>
    </div>
  );
}
